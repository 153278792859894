<template>
  <b-card
    :title="
      `${
        currentUser.organisation ? 'Update' : isSetup ? 'Setup' : 'Create'
      } Organisation`
    "
    sub-title="Please complete the following fields with the organisation's details."
    sub-title-tag="p"
  >
    <hr class="mt-7" />
    <b-form @submit.prevent="onSubmit" v-if="!isLoading">
      <b-row>
        <b-col md="4">
          <div class="p-2">
            <b-form-group
              id="input-group-1"
              label="Organisation name:"
              label-for="input-1"
            >
              <b-row>
                <b-form-input
                  id="input-1"
                  v-model="form.organisationName"
                  type="text"
                  required
                  placeholder="Enter organisation name"
                ></b-form-input>
              </b-row>
            </b-form-group>
          </div>
        </b-col>
        <b-col md="4">
          <div class="p-2">
            <b-form-group
              id="input-group-1"
              label="Contact Email:"
              label-for="input-1"
            >
              <b-row>
                <b-form-input
                  id="input-1"
                  v-model="form.contactEmail"
                  type="email"
                  required
                  placeholder="Enter email name"
                ></b-form-input>
              </b-row>
            </b-form-group>
          </div>
        </b-col>
        <b-col md="4">
          <div class="p-2">
            <b-form-group
              id="input-group-1"
              label="Physical address:"
              label-for="input-1"
            >
              <b-row>
                <b-form-input
                  id="input-1"
                  v-model="form.address"
                  type="text"
                  required
                  placeholder="Enter physical address"
                ></b-form-input>
              </b-row>
            </b-form-group>
          </div>
        </b-col>
        <b-col md="4">
          <div class="p-2">
            <b-form-group
              id="input-group-1"
              label="Contact number:"
              label-for="input-1"
            >
              <b-row>
                <b-form-input
                  id="input-1"
                  v-model="form.contactNumber"
                  type="tel"
                  required
                  placeholder="Enter contact number"
                ></b-form-input>
              </b-row>
            </b-form-group>
          </div>
        </b-col>
        <b-col md="4">
          <div class="p-2">
            <b-form-group
              id="input-group-1"
              label="Website:"
              label-for="input-1"
            >
              <b-row>
                <b-form-input
                  id="input-1"
                  v-model="form.website"
                  type="url"
                  required
                  placeholder="Enter website"
                ></b-form-input>
              </b-row>
            </b-form-group>
          </div>
        </b-col>
      </b-row>

      <div class="float-right">
        <b-button type="submit" variant="primary" ref="addOrganisationButton">{{
          currentUser.organisation ? "Update" : "Submit"
        }}</b-button>
      </div>
    </b-form>
  </b-card>
</template>
<script>
import { mapGetters } from "vuex";
import {
  ADD_ORGANISATION,
  GET_ORGANISATION,
  UPDATE_ORGANISATION,
} from "@/core/services/store/organisations.module";
import { SET_ACCOUNT_OBJECT } from "@/core/services/store/auth.module";

export default {
  created() {
    if (this.currentUser.organisation) {
      this.isEdit = new String(this.$router.history.current.fullPath).includes(
        "edit"
      );
      this.organisationId = this.$router.history.current.params.id;
      this.getOrganisation();
    }
  },
  props: ["isSetup"],
  data() {
    return {
      isLoading: false,
      isEdit: false,
      organisationId: null,
      form: {
        organisationName: "",
        contactEmail: "",
        address: "",
        contactNumber: "",
        website: "",
      },
    };
  },
  methods: {
    onSubmit() {
      const submitButton = this.$refs["addOrganisationButton"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      if (!this.currentUser.organisation)
        this.$store
          .dispatch(
            ADD_ORGANISATION,
            {
              ...this.form,
              createdBy: this.currentUser._id,
            },
            this.$router,
            !!this.isSetup
          )
          // go to which page after successfully login
          .then((a) => {
            this.$store.dispatch(SET_ACCOUNT_OBJECT, {
              ...this.currentUser,
              organisation: a,
            });
            this.form = {
              organisationName: "",
              contactEmail: "",
              address: "",
              contactNumber: "",
              website: "",
            };
            this.$bvToast.toast(
              "Your organisation has successfully been updated",
              {
                title: "Successfully submitted",
                variant: "success",
                solid: true,
                appendToast: true,
              }
            );
            // if (this.isSetup)
            this.$router.push("/dashboard");
          })
          .catch((e) => {
            console.log(e);
            this.$bvToast.toast(
              "Failed to update organisation. Please check your information and try again.",
              {
                title: "Submission failed",
                variant: "danger",
                solid: true,
                appendToast: true,
              }
            );
          })
          .finally(() => {
            this.isSubmitting = false;
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
      else
        this.$store
          .dispatch(UPDATE_ORGANISATION, {
            ...this.form,
            userId: this.currentUser._id,
            organisationId: this.currentUser.organisation._id,
          })
          // go to which page after successfully login
          .then((a) => {
            this.$store.dispatch(SET_ACCOUNT_OBJECT, {
              ...this.currentUser,
              organisation: a,
            });
            this.$bvToast.toast(
              "Your organisation has successfully been updated",
              {
                title: "Successfully submitted",
                variant: "success",
                solid: true,
                appendToast: true,
              }
            );
            // if (this.isSetup) this.$router.push("/dashboard");
          })
          .catch((e) => {
            console.log(e);
            this.$bvToast.toast(
              "Failed to update organisation. Please check your information and try again.",
              {
                title: "Submission failed",
                variant: "danger",
                solid: true,
                appendToast: true,
              }
            );
          })
          .finally(() => {
            this.isSubmitting = false;
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
          });
    },
    getOrganisation() {
      this.$store
        .dispatch(GET_ORGANISATION, this.currentUser.organisation._id)
        // go to which page after successfully login
        .then(
          (a) => {
            this.form = {
              organisationName: this.organisation.organisationName,
              contactEmail: this.organisation.contactEmail,
              address: this.organisation.address,
              contactNumber: this.organisation.contactNumber,
              website: this.organisation.website,
            };
          }
          // this.$bvToast.toast(
          //   "Your organisation has successfully been updated",
          //   {
          //     title: "Successfully submitted",
          //     variant: "success",
          //     solid: true,
          //     appendToast: true,
          //   }
          // )
        )
        .catch((e) => {
          console.log(e);
          this.$bvToast.toast("Failed to fetch organisation details.", {
            title: "Failed",
            variant: "danger",
            solid: true,
            appendToast: true,
          });
        })
        .finally(() => {
          this.isSubmitting = false;
          // submitButton.classList.remove(
          //   "spinner",
          //   "spinner-light",
          //   "spinner-right"
          // );
        });
    },
  },
  computed: {
    ...mapGetters(["currentUser", "organisation"]),
  },
};
</script>
