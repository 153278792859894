<template>
  <div class="d-flex flex-row flex-column-fluid page">
    <div id="kt_wrapper" class="d-flex flex-column flex-row-fluid">
      <div id="kt_content" class="content d-flex flex-column p-0">
        <Header class="left-0" />
        <div class="p-7 container" style="margin-top: 6rem">
          <h1>Setup Organisation</h1>
          <p class="mb-5">
            Before you can post your first application please setup the
            organisation's information that will appear under your listings.
          </p>
          <Organisation :isSetup="true" />
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>
<script>
import Organisation from "./Organisation.vue";
import Header from "../../layout/header/Header.vue";
import Footer from "../../layout/footer/Footer.vue";
export default {
  data() {
    return {};
  },
  components: { Organisation, Header, Footer },
};
</script>
<style scoped>
.left-0 {
  left: 0;
}
</style>
